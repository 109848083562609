/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import Meta from "vue-meta";

// Routes
import paths from "./paths";

Vue.use(Router);
Vue.use(Meta);

let nextUrl = null;

// Create a new router
const router = new Router({
  // base: '/control',
  mode: "history",
  routes: paths,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});
// Route guard checks to see if you are logged in
// insure your are routed back to requested url after login
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    checkAuthAndPermissions(to, from, next);

    // case no auth when required : happen in case of reload
    // we try to restore auth data from cookies and relaunch refresh loop
    store
      .dispatch("reload")
      // eslint-disable-next-line no-unused-vars
      .then((response) => {
        // we manage to reload the token
        checkAuthAndPermissions(to, from, next);
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error) => {
        // most probably token was not stored in cookies
        // console.log(error)
        next("/login");
      });
  } else {
    next();
  }
});

function checkAuthAndPermissions(to, from, next) {
  // Check Auth and Permissions
  if (store.getters.authorized) {
    // check requiresPermissions and userPermissions in requiresPermissions
    if (to.meta.requiresPermissions?.length) {
      // console.log("Page require permissions: ", to.meta.requiresPermissions);
      let matchPermissions = false;
      matchPermissions = store.getters.userPermissionsIDs.some((r) =>
        to.meta.requiresPermissions.includes(r)
      );

      // console.log("store.getters.userPermissionsIDs: ", store.getters.userPermissionsIDs);
      // console.log("to.meta.requiresPermissions: ", to.meta.requiresPermissions);

      if (!matchPermissions) {
        // console.log("Routing: User NOT has needed premission");
        const prevpage = from.path ? from.path : "/";
        next(prevpage);
        return;
      } else {
        // console.log("Routing: User has needed premission");
      }
    } else {
      // console.log("Routing: No req premissions needed - common page");
    }

    if (nextUrl) {
      // case I have next url, I have been redirected
      const url = nextUrl;
      nextUrl = null;
      next(url);
      return;
    }
    // case no next : most situation
    next();
    return;
  }
}

export default router;
