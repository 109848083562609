// authorized lets you know if the token is true or not
export default {
  authorized: (state) => !!state.token,
  getToken: (state) => state.token,
  userLoading: (state) => state.userLoading,
  user: (state) => state.user,
  userPermissionsIDs: (state) => state.user.permissions?.length ? state.user.permissions.map(p => p.id) : [],
  roles: (state) => state.roles,
  rolesIDs: (state) => state.roles.length ? state.roles.map(r => r.id) : [],
  cities: (state) => state.cities,
  companies: (state) => state.companies,
  statuses: (state) => state.statuses,
  lang: (state) => state.lang,
  categories: (state) => state.categories,
  templates: (state) => state.templates,
  permissions: (state) => state.permissions,
  sourceTypes: (state) => state.sourceTypes,
};
