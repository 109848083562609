import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import Vuelidate from "vuelidate";
import simplebar from "simplebar-vue";
import VueTour from "vue-tour";
import vco from "v-click-outside";
import * as VueGoogleMaps from "vue2-google-maps";
import i18n from "@/plugins/i18n";
import "@/plugins/axios";

import router from "./router";
import store from "@/store";
import { sync } from "vuex-router-sync"; // Sync router with store

import VueApexCharts from "vue-apexcharts";
import VueStringFilter from "vue-string-filter";
import Lightbox from "vue-easy-lightbox";

import urls from "@/urls";
import appConfig from "../app.config";

Vue.config.productionTip = false;

Vue.use(vco);

// As a plugin
import VueMask from "v-mask";
import VueQuillEditor from "vue-quill-editor";
import VueDraggable from "vue-draggable";
Vue.use(VueDraggable);
Vue.use(VueQuillEditor);
Vue.use(VueMask);

import VueSlideBar from "vue-slide-bar";

Vue.component("VueSlideBar", VueSlideBar);
Vue.component("apexchart", VueApexCharts);
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(require("vue-chartist"));
Vue.component("simplebar", simplebar);
Vue.use(VueStringFilter);
Vue.use(VueTour);
Vue.use(Lightbox);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places",
  },
  installComponents: true,
});

// Sync store with router
sync(store, router);

Vue.prototype.$urls = urls;
Vue.prototype.$appConfig = appConfig;
Vue.prototype.$scrollToTop = () => window.scrollTo(0,0)

import "@/assets/scss/app.scss";

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
