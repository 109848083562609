export default {
  token: "",
  userLoading: true,
  user: {
    email: "",
    name: "",
    lang: "",
    permissions: null,
  },
  lang: "",
  roles: "",
  cities: "",
  companies: "",
  statuses: "",
  categories: "",
  templates: "",
  permissions: "",
  sourceTypes: "",
};
