export default {
  logout(state) {
    state.token = "";
    state.user = "";
  },

  authSuccess(state, { token }) {
    state.token = token;
  },

  userLoading(state, data) {
    state.userLoading = data;
  },

  user(state, user) {
    state.user = user;

    // if user is owner set all permissions
    if (user.owner) {
      state.user.permissions = [...state.permissions];
    }
    
    // ### set lang from user: user.lang
    // if (!state.lang && user.lang) {
    //   state.lang = user.lang;
    // }
  },

  setlang(state, payload) {
    state.lang = payload;
  },
  setRoles(state, payload) {
    state.roles = payload;
  },
  setCities(state, payload) {
    state.cities = payload;
  },
  setCompanies(state, payload) {
    state.companies = payload;
  },
  setStatuses(state, payload) {
    state.statuses = payload;
  },
  setCategories(state, payload) {
    state.categories = payload;
  },
  setTemplates(state, payload) {
    state.templates = payload;
  },
  setPermissions(state, payload) {
    state.permissions = payload;
  },
  setSourceTypes(state, payload) {
    state.sourceTypes = payload;
  },
};
