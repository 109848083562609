<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  async created() {
    await this.$store.dispatch("fetchPermissions");

    if (this.$store.getters.authorized) {
      await this.$store.dispatch("fetchUserData");
    }
  },
};
</script>

<style lang="scss">
html {
  overflow-y: scroll;
}

body.modal-open {
  padding-right: 0 !important;
}

#sidebar-menu .router-link-exact-active,
#sidebar-menu .router-link-active {
  color: #3bafda;
}

.form-group label.required:after {
  content: " *";
  color: #f1556c;
}

.multiselect .multiselect__tags {
  border-radius: 0;
}

.multiselect.is-invalid .multiselect__tags {
  border-color: #f1556c !important;
}

.multiselect__select {
  z-index: 1;
}

.info-tooltip {
  .tooltip-inner {
    padding-left: 10px;
    padding-right: 10px;
    font-style: italic;
    font-size: 12px;
    line-height: 1.3;
  }
}

.mx-datepicker.is-invalid .mx-input-wrapper > input {
  border-color: #f1556c !important;
}

.b-table {
  .cell-collapse,
  .cell-id {
    width: 60px;
    text-align: center;
  }

  .cell-name {
    width: 300px;
  }

  .cell-action {
    width: 120px;
    text-align: center;
  }
}
</style>
