import Cookies from "js-cookie";
import urls from "@/urls";

export default {
  removeAuthCookies() {
    Cookies.remove("token");
  },

  storeAuthCookies(_, data) {
    Cookies.set("token", data.token, { sameSite: "strict" });
  },

  loadAuthStateFromCookies({ commit }) {
    let token = Cookies.get("token");
    if (typeof token === "undefined") {
      commit("logout");
      throw "unable to load auth cookies";
    } else {
      commit("authSuccess", { token });
    }
  },

  removeLangCookies() {
    Cookies.remove("lang");
  },

  storeLangCookies(_, lang) {
    Cookies.set("lang", lang, { sameSite: "strict" });
  },

  loadLangFromCookies({ commit }) {
    // console.log("loadLangFromCookies...");

    let lang = Cookies.get("lang");
    if (typeof lang !== "undefined") {
      commit("setlang", lang);
    }
  },

  user(context, user) {
    context.commit("user", user);
  },

  async login({ commit }, userData) {
    try {
      this.$axios.defaults.headers.common["X-Requested-With"] =
        "XMLHttpRequest";

      const { data } = await this.$axios.post(urls.URL_LOGIN, {
        email: userData.email,
        password: userData.password,
      });
      const token = data.access_token;
      commit("authSuccess", { token });
      this.dispatch("storeAuthCookies", { token });

      // don't load user, because user already in login data
      // await this.dispatch("fetchUserData");
      commit("user", data.user);
      commit("userLoading", false);

      // ### set lang from user: data.user.lang
      // if (data.user.lang) {
      //   this.dispatch("storeLangCookies", data.user.lang);
      // }

      // console.log("login success token: " + token);
    } catch (e) {
      commit("logout");
    }
  },

  async logout({ commit }) {
    try {
      this.$axios.defaults.headers.common["X-Requested-With"] =
        "XMLHttpRequest";
      await this.$axios.post(urls.URL_LOGOUT);
      // console.log("logout success");
    } catch (e) {
      // console.log("logout - error: ", e);
      commit("logout");
      this.dispatch("removeAuthCookies");
      this.dispatch("removeLangCookies");
    }
  },

  async reload() {
    await this.dispatch("fetchUserData");
    this.dispatch("loadAuthStateFromCookies");
    this.dispatch("loadLangFromCookies");
  },

  async fetchUserData({ commit }, loader = true) {
    // console.log("try, fetchUserData...");
    try {
      if (loader) commit("userLoading", true);
      const { data } = await this.$axios.get(urls.URL_USER);
      // console.log("userData: ", data);
      commit("user", data);
    } catch (error) {
      console.dir(error);
      this.dispatch("logout");
    } finally {
      if (loader) commit("userLoading", false);
    }
  },

  async fetchRoles({ commit }) {
    try {
      const { data } = await this.$axios(urls.URL_ROLES + "/all");
      commit("setRoles", data);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchCities({ commit }) {
    try {
      const { data } = await this.$axios(urls.URL_CITIES + "/all");
      commit("setCities", data);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchCompanies({ commit }) {
    try {
      const { data } = await this.$axios(urls.URL_COMPANIES + "/all");
      commit("setCompanies", data);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchStatuses({ commit }) {
    try {
      const { data } = await this.$axios(urls.URL_STATUSES);
      let statuses = [];
      for (const [id, title] of Object.entries(data)) {
        statuses.push({ id, title });
      }
      commit("setStatuses", statuses);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchCategories({ commit }) {
    try {
      const { data } = await this.$axios(urls.URL_CATEGORIES + "/all");
      commit("setCategories", data);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchTemplates({ commit }) {
    try {
      const { data } = await this.$axios(urls.URL_TEMPLATES + "/all");
      commit("setTemplates", data);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchPermissions({ commit }) {
    try {
      const { data } = await this.$axios(urls.URL_PERMISSIONS + "/all");
      commit("setPermissions", data);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchSourceTypes({ commit }) {
    try {
      const { data } = await this.$axios(urls.URL_SOURCETYPES);

      let types = Object.keys(data).map((key) => {
        return {
          id: Number(key),
          title: data[key],
        };
      });

      // ###temp, cut id = 4 - not used
      // console.log('types: ', types)
      types = types.filter((t) => t.id !== 4);
      // console.log('types: ', types)

      commit("setSourceTypes", types);
    } catch (error) {
      console.log(error);
    }
  },
};
