export default [
  {
    path: "*",
    meta: {
      name: "",
      requiresAuth: true, // true
    },
    redirect: {
      path: "/login",
    },
  },
  {
    path: "/",
    meta: {
      name: "",
      requiresAuth: true,
    },
    redirect: {
      path: "/home",
    },
  },
  {
    path: "/login",
    meta: {
      name: "Login",
      requiresAuth: false,
    },
    component: () => import("../views/pages/account/login"),
  },
  {
    path: "/home",
    meta: {
      name: "Home",
      requiresAuth: true, // true
    },
    component: () => import("../views/pages/home"),
  },
  {
    path: "/roles",
    meta: {
      name: "Roles",
      requiresAuth: true,
      requiresPermissions: [18],
    },
    component: () => import("../views/pages/roles/"),
  },
  {
    path: "/roles/create",
    meta: {
      name: "Create role",
      requiresAuth: true, 
      requiresPermissions: [20],
    },
    component: () => import("../views/pages/roles/create"),
  },
  {
    path: "/roles/view/:id",
    meta: {
      name: "View role",
      requiresAuth: true, 
      requiresPermissions: [19],
    },
    component: () => import("../views/pages/roles/view"),
  },
  {
    path: "/roles/edit/:id",
    meta: {
      name: "Edit role",
      requiresAuth: true,
      requiresPermissions: [21],
    },
    component: () => import("../views/pages/roles/edit"),
  },
  {
    path: "/users",
    meta: {
      name: "Users",
      requiresAuth: true, 
      requiresPermissions: [31],
    },
    component: () => import("../views/pages/users/"),
  },
  {
    path: "/users/create",
    meta: {
      name: "Create user",
      requiresAuth: true, 
      requiresPermissions: [33],
    },
    component: () => import("../views/pages/users/create"),
  },
  {
    path: "/users/view/:id",
    meta: {
      name: "View user",
      requiresAuth: true,
      requiresPermissions: [32],
    },
    component: () => import("../views/pages/users/view"),
  },
  {
    path: "/users/edit/:id",
    meta: {
      name: "Edit user",
      requiresAuth: true, 
      requiresPermissions: [34],
    },
    component: () => import("../views/pages/users/edit"),
  },
  {
    path: "/companies",
    meta: {
      name: "Companies",
      requiresAuth: true,
      requiresPermissions: [40],
    },
    component: () => import("../views/pages/companies/"),
  },
  {
    path: "/companies/create",
    meta: {
      name: "Create company",
      requiresAuth: true, 
      requiresPermissions: [42],
    },
    component: () => import("../views/pages/companies/create"),
  },
  {
    path: "/companies/view/:id",
    meta: {
      name: "View company",
      requiresAuth: true, 
      requiresPermissions: [41],
    },
    component: () => import("../views/pages/companies/view"),
  },
  {
    path: "/companies/edit/:id",
    meta: {
      name: "Edit company",
      requiresAuth: true,
      requiresPermissions: [43],
    },
    component: () => import("../views/pages/companies/edit"),
  },
  {
    path: "/cities",
    meta: {
      name: "Cities",
      requiresAuth: true, 
      requiresPermissions: [11],
    },
    component: () => import("../views/pages/cities/"),
  },
  {
    path: "/cities/create",
    meta: {
      name: "Create city",
      requiresAuth: true, 
      requiresPermissions: [13],
    },
    component: () => import("../views/pages/cities/create"),
  },
  {
    path: "/cities/view/:id",
    meta: {
      name: "View city",
      requiresAuth: true,
      requiresPermissions: [12],
    },
    component: () => import("../views/pages/cities/view"),
  },
  {
    path: "/cities/edit/:id",
    meta: {
      name: "Edit city",
      requiresAuth: true, 
      requiresPermissions: [14],
    },
    component: () => import("../views/pages/cities/edit"),
  },
  {
    path: "/categories",
    meta: {
      name: "Categories",
      requiresAuth: true, 
      requiresPermissions: [1],
    },
    component: () => import("../views/pages/categories/"),
  },
  {
    path: "/categories/create",
    meta: {
      name: "Create category",
      requiresAuth: true,
      requiresPermissions: [3],
    },
    component: () => import("../views/pages/categories/create"),
  },
  {
    path: "/categories/view/:id",
    meta: {
      name: "View category",
      requiresAuth: true,
      requiresPermissions: [2],
    },
    component: () => import("../views/pages/categories/view"),
  },
  {
    path: "/categories/edit/:id",
    meta: {
      name: "Edit category",
      requiresAuth: true, 
      requiresPermissions: [4],
    },
    component: () => import("../views/pages/categories/edit"),
  },
  {
    path: "/subcategories/create",
    name: "Create subcategory",
    meta: {
      requiresAuth: true, 
      requiresPermissions: [8],
    },
    component: () => import("../views/pages/subcategories/create"),
  },
  {
    path: "/subcategories/view/:id",
    name: "View subcategory",
    meta: {
      requiresAuth: true,
      requiresPermissions: [7],
    },
    component: () => import("../views/pages/subcategories/view"),
  },
  {
    path: "/subcategories/edit/:id",
    name: "Edit subcategory",
    meta: {
      requiresAuth: true,
      requiresPermissions: [9],
    },
    component: () => import("../views/pages/subcategories/edit"),
  },
  {
    path: "/templates",
    meta: {
      name: "Templates",
      requiresAuth: true,
      requiresPermissions: [25],
    },
    component: () => import("../views/pages/templates/"),
  },
  {
    path: "/templates/create",
    meta: {
      name: "Create template",
      requiresAuth: true,
      requiresPermissions: [27],
    },
    component: () => import("../views/pages/templates/create"),
  },
  {
    path: "/templates/view/:id",
    meta: {
      name: "View template",
      requiresAuth: true, 
      requiresPermissions: [26],
    },
    component: () => import("../views/pages/templates/view"),
  },
  {
    path: "/templates/edit/:id",
    meta: {
      name: "Edit template",
      requiresAuth: true, 
      requiresPermissions: [29],
    },
    component: () => import("../views/pages/templates/edit"),
  },
  {
    path: "/my-templates",
    meta: {
      name: "MyTemplates",
      requiresAuth: true,
    },
    component: () => import("../views/pages/my-templates/"),
  },
  // {
  //   path: "/my-templates/create",
  //   meta: {
  //     name: "Create template",
  //     requiresAuth: true, // true
  //     requiresPermissions: ["A"],
  //   },
  //   component: () => import("../views/pages/templates/create"),
  // },
  {
    path: "/my-templates/view/:id",
    meta: {
      name: "View my template",
      requiresAuth: true,
    },
    component: () => import("../views/pages/my-templates/view"),
  },
  {
    path: "/my-templates/edit/:id",
    meta: {
      name: "Edit my template",
      requiresAuth: true,
    },
    component: () => import("../views/pages/my-templates/edit"),
  },
  {
    path: "/wizard",
    meta: {
      name: "Wizard",
      requiresAuth: true,
    },
    component: () => import("../views/pages/wizard/"),
  },

  // {
  //   path: "/profile",
  //   meta: {
  //     name: "Profile",
  //     requiresAuth: true, // true
  //   },
  //   component: () => import("../views/pages/profile"),
  // },

];
