module.exports = {
  URL_LOGIN: "auth/login",
  URL_LOGOUT: "auth/logout",
  URL_USER: "auth/me",
  URL_USERS: "users",
  URL_COMPANIES: "companies",
  URL_ROLES: "roles",
  URL_STATUSES: "users/statuses",
  URL_CITIES: "cities",
  URL_CATEGORIES: "categories",
  URL_SUBCATEGORIES: "subcategories",
  URL_TEMPLATES: "templates",
  URL_SOURCES: "sources",
  URL_USER_TEMPLATES: "users/templates",
  URL_PERMISSIONS: "permissions",
  URL_SOURCE_GET_DATA: "templates/get-data",
  URL_GET_METRIC: "templates/get-metric",
  URL_SOURCETYPES: "templates/get-metric-type",
  URL_LOGIN_GPS: "templates/login-gps",
};
